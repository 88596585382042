.loader{
  position:fixed;
  min-height:100vh;
  /* height:100%; */
  z-index:9999;
  background-color:rgba(251,251,252,.7);
  width:100%;text-align:center;
  display:-webkit-flex;display:flex;
  -webkit-align-items:center;
  align-items:center;
  -webkit-justify-content:center;
  justify-content:center;top:0;left:0
}

.form-error{
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

/* Chrome, Safari, Edge, Opera */
input.hidden-arrow-input::-webkit-outer-spin-button,
input.hidden-arrow-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.hidden-arrow-input[type=number] {
  -moz-appearance: textfield;
}

/* sidebar-menu */
.sidebar-menu ul li a i{
  font-size: 20px;
}

.sidebar-menu > ul > li > a:hover{
  background-color: #3f88c7;
}

.sidebar-menu > ul > li.active > a:hover{
  background-color: #3f88c7;
}

.sidebar-menu li.active a {
  background-color: #3f88c7;
}

/* btn-primary */

.btn-primary{
  background-color: hsl(208, 68%, 34%);
  border-color: hsl(208, 68%, 34%);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: hsl(208, 68%, 34%);
  border-color: hsl(208, 68%, 34%);
}

.btn-primary:hover{
  background-color: #3f88c7;
  border-color: #3f88c7;
}

.btn-primary:active:not(:disabled):not(.disabled){
  background-color: #3f88c7;
  border-color: #3f88c7;
}

.btn-primary:focus{
  background-color: #3f88c7;
  border-color: #3f88c7;
}

/* switch */
.ant-switch-checked{
  background-color: #3f88c7;
}

/* case-details */
.case-details .case-img{
  height: 200px !important;
}

.case-details .blog{
  min-height: 322px;
}

.case-details .stl-viewer{
  width: 100%;
}

.color-box{
  width: 30px;
  height: 30px;
  border: 1px solid black;
  margin: 4px;
  float: left;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  
  .w-md-50 {
    width: 50% !important;
  }
  
  .w-md-75 {
    width: 75% !important;
  }
  
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  
  .w-lg-50 {
    width: 50% !important;
  }
  
  .w-lg-75 {
    width: 75% !important;
  }
  
  .w-lg-100 {
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  
  .w-xl-50 {
    width: 50% !important;
  }
  
  .w-xl-75 {
    width: 75% !important;
  }
  
  .w-xl-100 {
    width: 100% !important;
  }
}